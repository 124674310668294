<template>
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-col-2">
			<SplitButton :label="$t('Agregar')" @click="save" :model="items[i18n.locale()]" menuButtonIcon="pi pi-plus" v-if="inf.estado==11 || inf.estado==13"/>
		</div>
		<div class="p-col-12">
			
			<DataTable :value="$store.state.claim.pagos.filter(val => val.eliminar == false)" v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
				<template #header>
					<div class="table-header">
						{{ $t('Pagos') }}						
					</div>
				</template>
				<Column :header="$t('Beneficiario')">
					<template #body="slotProps">
						<strong>
							<InputText v-model="slotProps.data.name" type="text" :disabled="inf.estado==14 || slotProps.data.pagado"/>
						</strong>
					</template>
				</Column>
				<Column :header="$t('Fecha')">
					<template #body="slotProps">
						<InputMask v-model="slotProps.data.fecha" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999" :disabled="inf.estado==14 || slotProps.data.pagado"/>
					</template>
				</Column>
				<Column :header="$t('Banco')">
					<template #body="slotProps">
						<Dropdown v-model="slotProps.data.banco" :options="bancos" :optionLabel="$t('nombre')" :placeholder="$t('Seleccione...')" :disabled="inf.estado==14 || slotProps.data.pagado" @change="Validar()"/>
					</template>
				</Column>
				<Column :header="$t('Referencia')">
					<template #body="slotProps">
						<InputText v-model="slotProps.data.referencia" type="text" :disabled="inf.estado==14 || slotProps.data.pagado"/>
					</template>
				</Column>
				<Column :header="$t('Monto')">
					<template #body="slotProps">
						<InputText v-model="slotProps.data.monto" type="number" :disabled="inf.estado==14 || slotProps.data.pagado"/>
					</template>
				</Column>
				<Column :header="$t('Pagado')"  headerStyle="width: 10%">
					<template #body="slotProps">
						<Checkbox v-model="slotProps.data.pagado" :binary="true" @change="Calcular()"/>
					</template>
				</Column>					
				<Column :header="$t('Eliminar')" headerStyle="width: 10%">
                    <template #body="slotProps">
                        <Button v-if="!slotProps.data.pagado && (inf.estado==11 || inf.estado==13)" icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="Delete(slotProps.data)"/>
                    </template>
                </Column>
			</DataTable>
		</div>
	</div>
</template>

<script>
	import { useI18nPlugin } from '@unify/vuex-i18n';
	import Token from "uuid-token-generator";
	import url from "@/service/_URL";

	export default {
        props: {
            datos: {
                type: Object,
                default: null,
            },
            banc: {
                type: Object,
                default: null,
            },
        },
        data() {
			return {
				i18n: null,
                inf: this.datos,
                pagos: this.datos.pagos,
                bancos: this.banc,
                descripcion: null,
                selectedCustomers: null,
                filters: {},
                url: url,
                pdf: null,
				img: null,
				caso: 0,
				items: {
					en: [],
					es: []
				}
			}
		},
		created() {
			this.i18n = useI18nPlugin();
            const tokgen = new Token(256);
			this.pdf = tokgen.generate();
			this.img = tokgen.generate();
			this.Validacion();
			this.Bancos();
		},
        methods: {
			Validacion(){
				if(this.inf.lesiones){
					this.items.en.push({
						label: 'Injuries',
						command: () => {
							this.Agregar(2,'-');
						}
					});
					this.items.es.push({
						label: 'Lesiones',
						command: () => {
							this.Agregar(2,'-');
						}
					});
				}
				if(this.inf.beneficiario.taller_activo){
					this.items.en.push({
						label: 'Workshop',
						command: () => {
							this.Agregar(1,this.inf.beneficiario.taller);
						}
					});
					this.items.es.push({
						label: 'Taller',
						command: () => {
							this.Agregar(1,this.inf.beneficiario.taller);
						}
					});
				} else {
					this.items.en.push({
						label: 'Beneficiary',
						command: () => {
							this.Agregar(0,this.inf.beneficiario.nombre);
						}
					});
					this.items.es.push({
						label: 'Beneficiario',
						command: () => {
							this.Agregar(0,this.inf.beneficiario.nombre);
						}
					});
				}
			},
			Bancos(){
				for (var i = 0; i < this.$store.state.claim.pagos.length; i++) {
					if(this.$store.state.claim.pagos[i].banco){
						this.$store.state.claim.pagos[i].banco = this.bancos.filter( val => val.code == this.$store.state.claim.pagos[i].banco)[0];
					}
				}
			},
            Agregar(caso,name) {
				this.$store.state.claim.pagos.push({
					id: 0,
					type: caso,
                    fecha: null,
                    banco: null,
                    referencia: null,
					name: name,
					monto: 0,
					pagado: false,
					eliminar: false,
				});
			},
            Delete(selec) {
				this.$store.state.claim.pagos = this.$store.state.claim.pagos.filter(val => val !== selec);
			},
			Calcular(){
				var pagado = 0;
				var pagos = this.$store.state.claim.pagos.filter(val => val.pagado);
				pagos.forEach(element => {
					pagado = parseFloat(pagado) + parseFloat(element.monto);
				});
				this.inf.beneficiario.total = pagado;
			}
        }
	}
</script>
